export default class OrderedMixin
{
    public rowOrder: number = 0;

    setRowOrder(rowOrder: number): void
    {
        this.rowOrder = rowOrder;
    }

    getRowOrder(): number
    {
        return this.rowOrder;
    }
}